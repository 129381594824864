<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<!-- <a-form-item class="ui-form__item" name="organizationId" label="影院组织" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item> -->
					
					<a-form-item label="分类名称" name="title">
						<a-input v-model:value="formState.title" placeholder="请输入分类名称"/>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['snack_setting_classify_add']" type="primary" @click="onAdd">新增分类</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>			
			
			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'imgUrl'">
							<a-image :src="record.imgUrl" style="width: 60px;"></a-image>
						</template>
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['snack_setting_classify_edit']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
                    <div v-permission="['snack_setting_classify_open']" v-if="record.isDisabled" @click="onDisabled(record)">
                      <a-menu-item>
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['snack_setting_classify_disable']" v-else @click="onDisabled(record)">
                      <a-menu-item>
                        禁用
                      </a-menu-item>
                    </div>
										<div v-permission="['snack_setting_classify_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			
			<a-modal v-model:visible="showModal" destroyOnClose :title="modelRef.id ? '修改商品分类' : '新增商品分类'" width="750px" @ok="onSubmit">
				<a-spin :spinning="loading">
					<a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm" :labelCol="{span: 5}" :wrapperCol="{span: 15 }">	
						<a-form-item label="分类名称" name="title" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input :maxLength="6" v-model:value="modelRef.title" placeholder="请输入分类名称,最长6个字符"/>
						</a-form-item>

						<a-form-item name="imgUrl" label="图片" :rules="[{ required: true, message: '必填项不允许为空' }]" extra="建议图片比例：1 : 1">
							<a-upload
									v-model:file-list="fileList"
									name="file"
									list-type="picture-card"
									class="avatar-uploader"
									:show-upload-list="false"
									action="/admin/ajaxUpload.do"
									:before-upload="beforeUpload"
                  accept="image/*"
									@change="handleChange"
							>
								<img v-if="modelRef.imgUrl" :src="modelRef.imgUrl" alt="avatar" style="width: 100px;height: 100px;"/>
								<div v-else>
									<div class="ant-upload-text">Upload</div>
								</div>
							</a-upload>
						</a-form-item>
						
						<a-form-item label="优先级" name="sort" extra="优先级越高越靠前，不填则为0">
							<a-input-number :min="0" :precision="0" style="width: 100%;" v-model:value="modelRef.sort" placeholder="请输入"></a-input-number>
						</a-form-item>

            <a-form-item label="是否启用" name="isDisabled">
              <a-switch v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
            </a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getGoodsClassifyList, deleteGoodsClassify, getGoodsClassifyDetail, saveGoodsClassify, updateGoodsClassify } from '@/service/modules/mall.js';
  import {pullOnShelvesGoodsClassify, pullOffShelvesGoodsClassify} from "../../../../service/modules/mall";
	export default {
		components: {Icon },
		data() {
			return {
				loading: false,
				formState: {},
				organizationList: [],
				cinemaAllList: [],
				fileList: [],
				searchData: {},
				showModal: false,
				modelRef: {
          isDisabled: false,
				},
				list: [],
				columns: [{
					title: '分类名称',
					dataIndex: 'title'
				}, 
				{
					title: '分类图片',
					key: 'imgUrl'
				},
				{
					title: '优先级',
					dataIndex: 'sort'
				}, 
				{
					title: '状态',
					key: 'isDisabled'
				},
				{
					title: '操作',
					key: 'action'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				parentData: {},
				searchChildData: {},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getGoodsClassifyList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						classifyType: 2,
						parentId: 0,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
          postData.isShow = postData.isShow ? 1 : 0;
          postData.isDisabled = postData.isDisabled ? 0 : 1;
					postData.isPageClassify = postData.isPageClassify ? 1 : 0;
					postData.classifyType = 2;
					let ret;
					this.loading = true;
					try {
						if (this.isEdit) {
							ret = await updateGoodsClassify(postData);
						} else {
							postData.level = this.childVisible ? 2 : 1;
							postData.parentId = this.childVisible ? this.parentData.id : undefined
							ret = await saveGoodsClassify(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$refs.addForm.resetFields();
							this.showModal = false;
							if (this.childVisible) {
								this.getChildren();
							} else {
								this.getData();
							}
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			onAdd() {
				this.isEdit = false;
				this.showModal = true;
				this.modelRef = {};
        this.modelRef.isDisabled = true;
			},
			async onEdit(item) {
				this.loading = true;
				try {
					let ret = await getGoodsClassifyDetail({
						id: item.id
					});
					this.loading = false;
					if (ret.code === 200) {
						this.isEdit = true;
						ret.data.isShow = ret.data.isShow ? true : false;
						ret.data.isPageClassify = ret.data.isPageClassify ? true : false;
            ret.data.isDisabled = ret.data.isDisabled ? false : true;
						this.modelRef = ret.data;
						this.showModal = true;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await deleteGoodsClassify({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								if (this.childVisible) {
									this.getChildren();
								} else {
									this.getData();
								}
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			beforeUpload(file) {
			    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
			    if (!isJpgOrPng) {
			        this.$message.error('上传文件格式不正确');
			    }
			    const isLt1M = file.size / 1024 / 1024 < 1;
			    if (!isLt1M) {
			      this.$message.error('上传文件太大了');
			    }
			    return isJpgOrPng && isLt1M;
			},
			handleChange(info) {
			     if (info.file.status === 'done') {
			        //console.log(info.file);
			        this.fileList = info.fileList;
			        const $fileList = [...this.fileList];
			        this.fileList = $fileList.map(file => {
			            if (file.response) {
							this.modelRef.imgUrl = file.response.data.imgUrl;
			                file.url = file.response.data.imgUrl;
			            }
			            return file;
			        });
			    }
			},
      // onIsDisabled(item) {
      //   this.$confirm({
      //     title: '提示',
      //     content: '确认' + (item.isDisabled ? '启用' : '禁用') + '吗？',
      //     onOk: async () => {
      //       this.loading = true;
      //       try {
      //         let ret = await updateGoodsClassify({
      //           id: item.id,
      //           title: item.title,
      //           classifyType: 2,
      //           isDisabled: item.isDisabled ? 0 : 1
      //         })
      //         this.loading = false;
      //         if(ret.code === 200) {
      //           this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功')
      //           this.getData();
      //         }
      //       } catch(e) {
      //         this.loading = false;
      //       }
      //     }
      //   })
      // },
      onDisabled(item) {
        this.$confirm({
          title: '提示',
          content: '确定' + (item.isDisabled ? '启用' : '禁用') + '吗？',
          onOk: async () => {
            this.loading = true;
            try {
              let ret;
              if(item.isDisabled) {
                ret = await pullOnShelvesGoodsClassify({
                  id: item.id,
                  level: 1,
                })
              } else {
                ret = await pullOffShelvesGoodsClassify({
                  id: item.id,
                  level: 1,
                })
              }
              this.loading = false;
              if(ret.code === 200) {
                this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
                this.getData();
              }
            } catch(e) {
              this.loading = false;
            }
          }
        })
      },
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
